/*
#***********************************************
#
#      Filename: src/api/sentryBox/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 岗亭（换班）设置相关api接口
#        Create: 2021-11-10 15:27:39
# Last Modified: 2021-11-27 21:16:36
#***********************************************
*/
import request from '@/utils/request'

// 添加换班
export function add(data) {
  return request({
    url: '/sentryBox/add',
    method: 'post',
    data: data
  })
}

// 批量删除
export function batchDelete(data) {
  return request({
    url: '/sentryBox/batchDel',
    method: 'post',
    data: data
  })
}

// 删除数据
export function deleteItem(id) {
  return request({
    url: '/sentryBox/delete/' + id,
    method: 'post'
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/sentryBox/info' + id,
    method: 'get'
  })
}

// 数据列表
export function list(data) {
  return request({
    url: '/sentryBox/list',
    method: 'get',
    params: data
  })
}

// 信息更新
export function update(data) {
  return request({
    url: '/sentryBox/update',
    method: 'post',
    data: data
  })
}
