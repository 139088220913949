<!--
#***********************************************
#
#      Filename: src/views/Setting/ShiftChange.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 岗亭设置管理
#        Create: 2021-10-28 16:53:42
# Last Modified: 2021-10-28 16:54:07
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增岗亭')"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改岗亭')">
              编辑
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除岗亭', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="880px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="120px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="岗亭名称:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入岗亭名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="岗亭ip:" prop="ip">
              <el-input v-model="actionDataItem.ip" placeholder="请输入岗亭IP" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { add, deleteItem, list, update } from '@/api/sentryBox'

  export default {
    name: 'ShiftChange',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          ip: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          name: [{ required: true, message: '岗亭名称不能为空', trigger: 'blur' }],
          ip: [{ required: true, message: '岗亭对应的ip不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: false,
            filterToggleShow: false,
            tableHeadToggleShow: true,
            searchPlaceholder: ''
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: false,
            more: []
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '岗亭名称',
            isShow: true,
            width: '250',
            align: 'center',
            prop: 'name'
          },
          { label: '相机IP', isShow: true, align: 'center', prop: 'ip' }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'sentrybox:create',
          info: 'sentrybox:info',
          delete: 'sentrybox:delete',
          update: 'sentrybox:update',
          export: 'sentrybox:export'
        }
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      /**
       * 获取列表数据
       */
      getList() {
        if (!this.pid) {
          this.noticeError('未选定停车场')
          return
        }
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        queryData.pId = this.pid

        list(queryData)
          .then((res) => {
            _this.dataList = res.data.records
            _this.total = res.data.total
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.modalObject.currentAction === 'edit') {
              const postData = new FormData()
              postData.set('id', _this.actionDataItem.id)
              postData.set('name', _this.actionDataItem.name)
              postData.set('ip', _this.actionDataItem.ip)
              postData.set('pId', _this.pid)
              console.log(postData)
              update(postData)
                .then((res) => {
                  _this.noticeSuccess('更新信息成功')
                  _this.handleRefresh()
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally(() => {
                  _this.modalObject.status = false
                })
            } else {
              const postData = new FormData()
              postData.set('name', _this.actionDataItem.name)
              postData.set('ip', _this.actionDataItem.ip)
              postData.set('pId', _this.pid)

              add(postData)
                .then((res) => {
                  _this.noticeSuccess('添加岗亭成功')
                  _this.handleRefresh()
                })
                .catch((e) => {
                  _this.noticeError(e.message)
                })
                .finally((_this.modalObject.status = false))
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.noticeSuccess('删除成功')
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>
